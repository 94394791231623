const opinionsPrevBtn = document.querySelector('.opinions__prev');
const opinionsNextBtn = document.querySelector('.opinions__next');
const casesPrevBtn = document.querySelector('.cases__prev');
const casesNextBtn = document.querySelector('.cases__next');

const opinionsSlider = new KeenSlider(
	'.opinions__slider',
	{
		loop: true,
		slides: {
			perView: 1.2,
		},
		breakpoints: {
			'(min-width: 992px)': {
				slides: {
					perView: 1.75,
				},
			},
		},
	},
	[
		opinionsSlider => {
			const dots = document.querySelector('.opinions__slider-dots');

			const slidesElements = opinionsSlider.slides;
			let slidesArr, activeSlideIdx;

			opinionsSlider.on('created', () => {
				slidesArr = opinionsSlider.track.details.slides;
				activeSlideIdx = opinionsSlider.track.details.rel;
				slidesArr.forEach((slide, idx) => {
					const dot = document.createElement('button');
					dot.classList.add('keen-slider__dot');
					dot.ariaLabel = 'slider navigation button'
					idx === activeSlideIdx ? dot.classList.add('active') : dot.classList.remove('active');
					dot.addEventListener('click', () => opinionsSlider.moveToIdx(idx));
					dots.append(dot);
				});
			});

			opinionsSlider.on('slideChanged', () => {
				activeSlideIdx = opinionsSlider.track.details.rel;
				const allDots = Array.from(dots.children);

				allDots.forEach((dot, idx) => {
					idx === activeSlideIdx ? dot.classList.add('active') : dot.classList.remove('active');
				});

				slidesElements.forEach((slide, idx) => {
					idx === activeSlideIdx ? slide.classList.add('active') : slide.classList.remove('active');
				});
			});
		},
	]
);

const casesSlider = new KeenSlider(
	'.cases__slider',
	{
		loop: true,
		slides: {
			perView: 1.2,
			spacing: 32,
		},
		breakpoints: {
			'(min-width: 992px)': {
				slides: {
					perView: 3.25,
					spacing: 32,
				},
			},
		},
	},
	[
		casesSlider => {
			const dots = document.querySelector('.cases__slider-dots');

			// const slidesElements = casesSlider.slides;
			let slidesArr, activeSlideIdx;

			casesSlider.on('created', () => {
				slidesArr = casesSlider.track.details.slides;
				activeSlideIdx = casesSlider.track.details.rel;
				slidesArr.forEach((slide, idx) => {
					const dot = document.createElement('button');
					dot.classList.add('keen-slider__dot');
					dot.ariaLabel = 'slider navigation button'
					idx === activeSlideIdx ? dot.classList.add('active') : dot.classList.remove('active');
					dot.addEventListener('click', () => casesSlider.moveToIdx(idx));
					dots.append(dot);
				});
			});

			casesSlider.on('slideChanged', () => {
				activeSlideIdx = casesSlider.track.details.rel;
				const allDots = Array.from(dots.children);

				allDots.forEach((dot, idx) => {
					idx === activeSlideIdx ? dot.classList.add('active') : dot.classList.remove('active');
				});
			});
		},
	]
);

opinionsPrevBtn.addEventListener('click', () => opinionsSlider.prev());
opinionsNextBtn.addEventListener('click', () => opinionsSlider.next());
casesPrevBtn.addEventListener('click', () => casesSlider.prev());
casesNextBtn.addEventListener('click', () => casesSlider.next());